<template>
  <div class="step__body">
    <DimensionOption :option="step.width" option-dim="dimension.width" :type="width"/>
    <DimensionOption :option="step.height" option-dim="dimension.height" :height-max="heightMax" :type="height"/>
  </div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import DimensionOption from './dimensionOption'
export default {
  name: 'Dimension',
  components: {
    DimensionOption
  },
  setup () {
    const heightMax = ref(0)
    const store = useStore()
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    const dimension = computed(() => {
      const steps = store.getters['configurator/steps']
      const options = steps.find(s => s.model_step).options
      const option = options.find(o => o.selected)
      const arr = option.stepcial_dim.map(s => {
        if ((s.width.from <= Number.parseInt(step.width.value) && s.width.to >= Number.parseInt(step.width.value)) && s.price > 0) {
          return Math.max(s.height.to)
        }
      })
      arr.filter(a => {
        if (typeof a !== 'undefined') {
          heightMax.value = a
        }
      })
      return option.dimension
    })
    return {
      step,
      dimension
    }
  }
}
</script>
